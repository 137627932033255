.google-signin-button {
  overflow: hidden;
  text-indent: -1000000px;
  display: block;
  width: 191px;
  height: 46px;
  background-image: url('./btn_google_signin_dark_normal_web.png');
  background-repeat: no-repeat;
  &:hover {
    cursor: pointer;
    background-image: url('./btn_google_signin_dark_pressed_web.png');
  }
  &:focus {
    background-image: url('./btn_google_signin_dark_focus_web.png');
    background-image: url('./btn_google_signin_dark_pressed_web.png');
  }
  &.disabled {
    cursor: default;
    background-image: url('./btn_google_signin_dark_disabled_web.png');
  }
}
